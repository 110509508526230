//
// Home features
//

$cardBackgroundLightenAmount: 10%;

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 2
  );

  // Choose your card style for home feature cards
  // .homeBox1 {
  //   @include card;
  //   @include card-side-by-side(
  //     $prefab: 2,
  //     $card-side-by-side-vertical-text-position: center
  //   );
  // }
  .homeFeature:nth-child(odd) {
    @include card(
      $card-details-background-colour: lighten($donate-colour, $cardBackgroundLightenAmount),
      $card-heading-colour: text-contrast($card-details-background-colour),
      $card-summary-colour: text-contrast($card-details-background-colour)
    );
    @include card-basic(
      $prefab: 3
    );
  }
  .homeFeature:nth-child(even) {
    @include card(
      $card-details-background-colour: lighten($brand-secondary, $cardBackgroundLightenAmount),
      $card-heading-colour: text-contrast($card-details-background-colour),
      $card-summary-colour: text-contrast($card-details-background-colour)
    );
    @include card-basic(
      $prefab: 3
    );
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

// Choose your card style for home feed cards
.feedItem:nth-child(even) {
  @include card(
    $card-details-background-colour: lighten($donate-colour, $cardBackgroundLightenAmount),
    $card-heading-colour: text-contrast($card-details-background-colour),
    $card-summary-colour: text-contrast($card-details-background-colour)
  );
  @include card-basic(
    $prefab: 3
  );
}
.feedItem:nth-child(odd) {
  @include card(
    $card-details-background-colour: lighten($brand-secondary, $cardBackgroundLightenAmount),
    $card-heading-colour: text-contrast($card-details-background-colour),
    $card-summary-colour: text-contrast($card-details-background-colour)
  );
  @include card-basic(
    $prefab: 3
  );
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card(
    $card-heading-colour: #fff,
    $card-summary-colour: #fff,
    $card-details-background-colour: $brand-primary
  );
  @include card-basic;
}

//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}