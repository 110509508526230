.carouselSlide{
  overflow: visible;
}

// Visual appeal demo
@include appeal-field("sample-appeal-post-2", "flowers");
body.appeal-sample-appeal-post-2 {
  background-color: #fff;
}

@media (min-width: map-get($map: $breakpoints, $key: lg) + 1) {
  .menuMain,
  .pageHeader .headerContent > *:not(.mainLogo) {
    margin-top: -54px;
  }
}

.homeIntroWrapper {
  display: none;
}

.homeFeature,
.feedItem {
  .homeFeatureDetailsWrapper,
  .feedItemDetailsWrapper,
  .postFooterWrapper {
    box-shadow: rgba($body-colour, 0.25) 0px 2px 5px;
  }
  &:hover {
    .feedItemDetailsWrapper,
    .postFooterWrapper {
      box-shadow: rgba($body-colour, 0.25) 0px 15px 15px;
    }
  }
}

@media (max-width: map-get($map: $breakpoints, $key: lg)) {
  .homeFeatures {
    padding-top: 2.5rem;
  }
}

.homeFeed:not(:first-of-type) {
  padding-top: 0;
}

// Donate form stuff
.formQuestion.donationAmount:not(.ccAmount).donationSelected,
.formQuestion.donationPeriod, .formQuestion.donationDate {
  background-color: $brand-secondary;
}

.formQuestion.donationFrequency > div.active {
  color: #fff;
}

// Blockquote
blockquote {
  border-radius: $border-radius * 2;
  border-left: 3px solid $brand-primary;
  .quote-attribute {
    font-size: $font-size-base * 0.9;
  }
  *:first-child:before {
    display: inline-block;
    font-family: $icon-font-family;
    content: "\f10d";
    margin-right: 10px;
  }
  &.contains-attribution {
    *:nth-last-child(2):after {
      display: inline-block;
      font-family: $icon-font-family;
      content: "\f10e";
      margin-left: 10px;
    }
  }
  &:not(.contains-attribution) {
    *:nth-last-child(1):after {
      display: inline-block;
      font-family: $icon-font-family;
      content: "\f10e";
      margin-left: 10px;
    }
  }
  &.dark {
    background-color: $brand-primary;
    border-left-color: $brand-secondary;
    color: #fff;
  }
  &.pink {
    background-color: $donate-colour;
    color: #fff;
    border-left-color: $brand-primary;
  }
  &.compact {
    font-size: 1.25em;
    background-color: transparent;
    border-left-width: 5px;
    *:before, *:after {
      font-size: 0.8em !important;
      transform: translatey(-5px);
    }
  }
}

.eventWidgetsWrapper {
  display: none;
}

@media (max-width: 576px) {
  .formQuestion.donationFrequency {
    font-size: 1.15em;
  }
  .carouselSlideSummary {
    font-size: 1.1rem;
  }
}

.donationPeriod label,
.donationDate label {
  font-size: 1.1em;
  font-weight: bold;
}

@include max-width(lg) {
  body {
    font-size: $font-size-base;
  }
}

@include max-width(md) {
  body {
    font-size: $font-size-base;
  }
}

.donationAmountOther {
  border-width: 3px !important;
}

// QA
.shareWrapper {
  .socialIcons a:before, .shareList a:before {
    color: #fff;
  }
}

// https://raisingit.atlassian.net/browse/DEL-117
@include appeal-field("christmas-lights-appeal", "christmas");
@include appeal-field("flowers-of-remembrance-appeal", "flowers");
@include appeal-field("shining-stars-appeal", "stars");

body {
  &.appeal-christmas-lights-appeal,
  &.appeal-shining-stars-appeal {
    a.button.callToActionBtn.donateBtn.donate {
      background-color: $brand-pink;
      transition: all .3s ease-in-out;
      &:hover {
        background-color: darken($brand-pink, 10%);
      }
    }
  }
}