// Config values
$platform-name: "lewishamandgreenwichnhstrust";


// Colours
$blue-accessible: #216fb7;
$brand-primary: #338ED8;
$brand-pink: #EC4A8E;
// $brand-primary-text-contrast-override: null !default;
$brand-secondary: #41B6E6;
// $brand-secondary-text-contrast-override: null !default;

$donate-colour: $brand-pink;

// Create greyscale
$black: #000 !default;
$grey-dark: #292b2c !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

// Utility colours
// $danger-colour--on-white: #dc3545;
// $danger-colour--on-background: #721c24;
// $danger-colour--background: #f8d7da;
// $warning-colour--on-white: #f0ad4e;
// $warning-colour--on-background: #856404;
// $warning-colour--background: #FFF3CD;
// $success-colour--on-white: #008A00;
// $success-colour--on-background: #155724;
// $success-colour--background: #d4edda;
// $accessibility-highlight-colour: #fd0 !default;
// $accessibility-highlight-box-shadow: 0 4px #0b0c0c !important;


// Layout
// $container-max-width: 1230px !default;
// $gap-width: 30px !default;
// $site-bleed: 20px !default;

$border-radius: 0;
$border-colour: $grey-lighter !default;
// $box-shadow: none !default;


// Breakpoints
// $breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 1024px,
//   xl: 1200px
// ) !default;


// Spacers
$spacer: 1rem !default;
// $spacer-y: $spacer !default;
// $spacer-x: $spacer !default;


// Misc
// $body-bg: $white !default;
$body-colour: #1F1F1F;
// $profile-pics-enabled: true !default; // Turn off donor, fundraiser, blog author photos sitewide
// $border-width: 0;
// $date-ordinals-enabled: true !default; // Turn off 'st', 'nd', 'rd' from dates


// Transitions
// $transition-duration: 0.1s !default;
// $transition-duration-slow: 0.5s !default;
// $transition-curve: ease !default;


// Logo
// $logo: "logo.svg";
// $logo-retina: $logo !default;
$logo-width: 340px;
$logo-height: 156px;
// $logo-absolute: true; // true if we want the logo to vertically intersect with the menu
// $logo-breakpoint: map-get($breakpoints, md) !default;
// $logo-mobile: $logo !default;
// $logo-mobile-retina: $logo-mobile !default;
// $logo-mobile-width: $logo-width * 0.75 !default;
// $logo-mobile-height: $logo-height * 0.75 !default;


// Typography
$font-family-base: 'Mulish', sans-serif;
$font-size-base: 1.05rem !default;
$font-size-large: 1.25em !default;
$font-size-small: 1em !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;
// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;

// $font-size-h1: 2.5em !default;
// $font-size-h2: 2em !default;
// $font-size-h3: 1.75em !default;
// $font-size-h4: 1.5em !default;
// $font-size-h5: 1.25em !default;
// $font-size-h6: 1em !default;

// $headings-margin-top: 0.5em !default; // This will only affect headings within post content
// $headings-margin-bottom: 0.5em !default;
// $headings-font-family: inherit !default;
// $headings-font-weight: $font-weight-bold !default;
// $headings-line-height: 1.1 !default;
// $headings-colour: inherit !default;
// $headings-text-transform: none !default;
// $headings-letter-spacing: initial !default;

// $hr-border-colour: $border-colour !default;
// $hr-border-width: $border-width !default;

// Links
// $link-colour: $brand-primary !default;
// $link-decoration: none !default;
// $link-decoration-colour: initial !default;
// $link-hover-decoration: underline !default;
// $link-hover-style: darken !default; // darken or lighten text links on hover

// Icon font
// $icon-font-family: "FontAwesome"; // TODO: Add in alternatives here


// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: white;
$btn-border-radius: 2px;
$btn-border-width: $border-radius;
$btn-border-colour: $brand-primary;
// $btn-box-shadow: $box-shadow !default;
// $btn-padding-x: 1em !default;
// $btn-padding-y: 0.5em !default;
// $btn-font-family: $font-family-base !default;
$btn-font-weight: bold;
$btn-font-size: $font-size-large;
// $btn-text-transform: none !default;
// $btn-text-decoration: none !default;
// $btn-line-height: 1.25 !default;
// $btn-letter-spacing: inherit !default;
// $btn-hover-style: darken !default; // darken or lighten the button on hover
// $btn-transition-duration: $transition-duration !default;

// Donate button
$donate-btn-background-colour: $brand-primary;
$donate-btn-colour: white;


// Social icons (https://github.com/RaisingITDev/fresco/wiki/social-icons)
// Social network colours - use these by changing $social-icons-use-brand-colours to true
// $twitter-brand-colour: #1da1f2 !default;
// $facebook-brand-colour: #3b5998 !default;
// $youtube-brand-colour: #ff0000 !default;
// $instagram-brand-colour: #833ab4 !default;
// $linkedin-brand-colour: #0077b5 !default;
// $pinterest-brand-colour: #bd081c !default;
// $tripadvisor-brand-colour: #00af87 !default;
// $vimeo-brand-colour: #1ab7ea !default;

// $social-icons-background-colour: $btn-background-colour !default; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
// $social-icons-hover-colour: $social-icons-colour !default; // A colour, or 'brand'
// $social-icons-width: 40px !default;
// $social-icons-height: 40px !default;
// $social-icons-font-size: 1.2rem !default;
// $social-icons-gap: 5px !default;
// $social-icons-border-radius: $btn-border-radius !default;
// $social-icons-box-shadow: $btn-box-shadow !default;

// Header specific
$social-icons-header-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-header-colour: $brand-primary; // A colour, or 'brand'
// $social-icons-header-hover-colour: $social-icons-hover-colour !default; // A colour, or 'brand'

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
// $social-icons-footer-colour: $social-icons-colour !default; // A colour, or 'brand'
// $social-icons-footer-hover-colour: $social-icons-hover-colour !default; // A colour, or 'brand'


// Share this page
// $share-enabled: true !default;
// $share-text-align: left !default; // left, center, right
// $share-label-font-family: $font-family-base !default;
// $share-label-font-size: $font-size-h5 !default;
// $share-label-font-colour: $body-colour !default;


// Form elements
$input-padding-y: 0.375rem !default;
$input-padding-x: 0.75rem !default;
// $input-line-height: 1.25 !default;
// $input-colour: $body-colour !default;
// $input-border-colour: #ced4da !default;
// $input-border: 1px solid $input-border-colour !default;
// $input-border-radius: $border-radius !default;
// $input-placeholder-colour: $grey-light !default;

// Field groups
// $field-group-label-width: 280px !default;


// Tables
// $table-cell-padding: 0.75rem !default;


// Card
// $card-gap-width: $gap-width !default;
// $card-border-radius: $border-radius !default;
$card-border: 0;
// $card-box-shadow: $box-shadow !default;
// $card-text-align: left !default;
// $card-image-on: true !default;
// $card-image-opacity: 1 !default;
// $card-image-background-colour: $brand-primary !default;
// $card-image-padding: 0 !default;
// $card-image-border-radius: 0 !default;
$card-details-padding: $spacer * 2;
// $card-details-background-colour: $white !default;
// $card-details-background-gradient: none !default; // none or CSS gradient
// $card-heading-font-family: $headings-font-family !default;
// $card-heading-text-transform: $headings-text-transform !default;
// $card-heading-colour: $link-colour !default;
// $card-heading-font-size: $font-size-h3 !default;
// $card-heading-margin-bottom: $spacer / 2 !default;
// $card-summary-enabled: true !default;
// $card-summary-colour: text-contrast($card-details-background-colour) !default;
// $card-summary-font-size: $font-size-base !default;
// $card-footer-on: false !default;
// $card-footer-background-colour: rgba($black, 0.05) !default;
// $card-footer-background-gradient: none !default; // none or CSS gradient
// $card-footer-colour: $body-colour !default;
// $card-footer-padding: 0.5rem $card-details-padding !default;
// $card-min-width: 280px !default;

// Card basic
// $card-basic-image-position: above !default; // above, below

// Card text overlay
// $card-text-overlay-show-summary-on-hover: true !default;
// $card-text-overlay-summary-always-on: false !default;
// $card-text-overlay-details-max-width: 100% !default;
// $card-text-overlay-details-position-y: bottom !default; // top, center, bottom
// $card-text-overlay-details-position-x: left !default; // left, center, right
// $card-text-overlay-breakpoint: map-get($breakpoints, sm) !default; // When do you want to break the text under image?

// Card text over
// $card-text-over-image-vertical-text-position: center !default; // top, center or bottom
// $card-text-over-image-darken-image-amount: 0.5 !default;
// $card-text-over-image-tint-colour: $black !default;
// $card-text-over-image-show-summary-on-hover: true !default;

// Card side by side
// $card-side-by-side-image-position: left !default;
// $card-side-by-side-image-width: 50% !default;
// $card-side-by-side-grow-image: true !default; // Do you want the image to scale to 100% height of the overall card?
// $card-side-by-side-vertical-text-position: top !default; // top, center or bottom
// $card-side-by-side-breakpoint: map-get($breakpoints, sm) !default; // When do you want to break the text under image?

// Card hover state
// $card-hover-border-colour: $border-colour !default;
// $card-hover-box-shadow: $card-box-shadow !default;
$card-hover-image-opacity: 1.0;
// $card-hover-image-scale: 1 !default;
// $card-hover-details-background-colour: $card-details-background-colour !default;
// $card-hover-details-background-gradient: $card-details-background-gradient !default; // none or CSS gradient
// $card-hover-heading-colour: $card-heading-colour !default;
// $card-hover-summary-colour: text-contrast($card-hover-details-background-colour) !default;

// Event cards
// $event-card-floating-date: false !default;
// $event-card-floating-date-background-colour: $card-details-background-colour !default;
// $event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour) !default;
// $event-card-floating-date-border-radius: $border-radius !default;
// $event-card-floating-date-margin-y: $spacer !default;
// $event-card-floating-date-margin-x: $spacer !default;


// Global alert
// $global-alert-background-colour: $brand-primary !default;
// $global-alert-colour: text-contrast($global-alert-background-colour) !default;


// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100% !default; // $container-max-width
// $menu-admin-background-colour: $grey-lighter !default;
// $menu-admin-background-gradient: none !default; // none or CSS gradient
// $menu-admin-contents-max-width: $container-max-width !default; // 100%
// $menu-admin-font-size: $font-size-small !default;
// $menu-admin-align-items: right !default; // left, center, right
// $menu-admin-link-colour: $link-colour !default;
// $menu-admin-absolute: false !default;
// $menu-admin-login-link-enabled: true !default;
// $menu-admin-manager-link-enabled: true !default;
// $menu-admin-my-details-link-enabled: true !default;
// $menu-admin-logout-link-enabled: true !default;
// $menu-admin-to-nav-breakpoint: 0px !default;


// Header
$header-background-colour: white;
// $header-background-gradient: none !default; // none or CSS gradient
// $header-box-shadow: $box-shadow !default;

// $header-absolute: false !default; // true if you want the header to appear transparently over banner images
// $header-absolute-background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0)) !default; // Colour or gradient
// $header-absolute-breakpoint: map-get($breakpoints, sm) !default; // When do you want to abandon the absolute header and return to solid?

// Header content
// $header-content-max-width: $container-max-width !default; // 100%
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer * .5;
$header-content-padding-bottom: $spacer * .5;
// $header-content-padding-left: $site-bleed !default;
// $header-content-padding-right: $site-bleed !default;

// Tagline
// $tagline-enabled: false !default;
// $tagline-font-family: $headings-font-family !default;
// $tagline-font-weight: $font-weight-bold !default;
// $tagline-font-size: $font-size-h5 !default;
// $tagline-colour: $headings-colour !default;
// $tagline-above-cta-breakpoint: 0 !default;
// $tagline-breakpoint: map-get($breakpoints, md) !default;

// Main call to action
// $cta-btn-background-colour: $btn-background-colour !default;
// $cta-btn-colour: $btn-text-colour !default;
// $cta-btn-gap: 5px !default;
// $cta-padding-right-at-nav-breakpoint: 6.5em !default; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint
// $cta-btn-breakpoint: map-get($breakpoints, sm) !default;
// $cta-donate-on-appeal: false !default; // Do you want to show the main cta donate button when on appeal post/donate form?

// Search
// $header-search-enabled: true !default;
// $header-search-margin-right: 20px !default;
// $header-search-breakpoint: map-get($breakpoints, lg) !default;

// Search - input
// $header-search-input-max-width: 200px !default;
// $header-search-input-height: initial !default; // If you need to specificy a specific height here
$header-search-input-margin-right: -45px;
$header-search-input-padding: $input-padding-y 45px $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $body-colour;
// $header-search-input-border-radius: $border-radius !default;
$header-search-input-border-colour: $body-colour;
$header-search-input-border-width: 0 0 1px 0;
$header-search-input-background-colour: transparent;
// $header-search-input-colour: text-contrast($header-search-input-background-colour) !default;
// $header-search-input-focus-background-colour: $white !default;
// $header-search-input-focus-colour: text-contrast($header-search-input-focus-background-colour) !default;

// Search - button
// $header-search-button-width: 40px !default;
// $header-search-button-height: 40px !default;
$header-search-button-background-colour: transparent;
// $header-search-button-icon-font-size: $social-icons-font-size !default;
$header-search-button-icon-colour: $body-colour;
//$header-search-button-border-radius: 0 0 2px 0;

// Search when in nav
// $nav-search-enabled: $header-search-enabled !default;
// $nav-search-input-margin-right: 1rem !default;
// $nav-search-input-padding: $input-padding-y $input-padding-x !default;
// $nav-search-input-placeholder-colour: $header-search-input-placeholder-colour !default;
// $nav-search-input-border-colour: $header-search-input-border-colour !default;
// $nav-search-input-border-width: 0 !default; // Should already contrast enough with the menu bg
// $nav-search-input-background-colour: $header-search-input-background-colour !default;
// $nav-search-button-background-colour: $header-search-button-background-colour !default;
// $nav-search-button-icon-colour: text-contrast($nav-search-button-background-colour) !default;

// Social icons -- Colours set above (search for '$social-icons-header')
// $header-social-icons-margin-right: 20px !default;
// $header-social-icons-margin-bottom: 0 !default;
// $header-social-icons-above-cta-buttons: false !default;
// $header-social-icons-breakpoint: map-get($breakpoints, lg) !default;


// Sticky header
// $header-sticky-enabled: false !default;
// $header-sticky-background-colour: $header-background-colour !default;
// $header-sticky-box-shadow: 0 0 10px rgba($black, 0.1) !default;
// $header-sticky-logo: $logo !default;
// $header-sticky-logo-retina: $header-sticky-logo !default;
// $header-sticky-logo-width: $logo-width * 0.5 !default;
// $header-sticky-logo-height: $logo-height * 0.5 !default;
// $header-sticky-menu-admin-enabled: false !default;
// $header-sticky-search-enabled: false !default;
// $header-sticky-social-icons-enabled: false !default;
// $header-sticky-cta-btns-enabled: true !default;
// $header-sticky-header-content-margin-top: $header-content-margin-top * 0.75 !default;
// $header-sticky-header-content-margin-bottom: $header-content-margin-bottom * 0.75 !default;
// $header-sticky-nav-margin-bottom: 0 !default;


// Navigation
// $nav-type: normal !default; // normal, flyout, fullscreen // TODO: mega
// $nav-font-family: $font-family-base !default;
// $nav-breakpoint: map-get($breakpoints, lg) !default;
$nav-background-colour: transparent;
// $nav-text-transform: none !default;

// Top level items
$nav-top-level-item-padding: 15px 20px;
$nav-top-level-item-colour: $body-colour;
// $nav-top-level-item-font-family: $nav-font-family !default;
// $nav-top-level-item-font-size: $font-size-base !default;
$nav-top-level-item-font-weight: $font-weight-bold;
// $nav-top-level-item-background-colour: transparent !default;
// $nav-top-level-item-hover-colour: $nav-top-level-item-colour !default;
// $nav-top-level-item-hover-background-colour: rgba($black, 0.1) !default;
// $nav-top-level-chevrons-enabled: false !default;
// $nav-top-level-chevrons-colour: $nav-top-level-item-colour !default;
// $nav-top-level-first-item-home-icon: false !default;
// $nav-top-level-first-item-home-icon-font-size: 20px !default;

// Submenus
// $nav-submenu-width: 220px !default;
$nav-submenu-background-colour: $grey-lightest !default;
// $nav-submenu-box-shadow: none !default;
// $nav-submenu-border-radius: $border-radius !default;
// $nav-submenu-item-padding: 10px 20px !default;
$nav-submenu-item-colour: text-contrast($nav-submenu-background-colour);
// $nav-submenu-item-font-family: $nav-font-family !default;
// $nav-submenu-item-font-size: $font-size-base !default;
$nav-submenu-item-font-weight: bold;
// $nav-submenu-item-hover-colour: $nav-submenu-item-colour !default;
// $nav-submenu-item-hover-background-colour: rgba($black, 0.1) !default;
// $nav-submenu-chevrons-enabled: true !default;
// $nav-submenu-chevrons-colour: $nav-submenu-item-colour !default;

// Burger button
// $burger-btn-background-colour: $btn-background-colour !default;
// $burger-btn-text-colour: $btn-text-colour !default;
// $burger-btn-text-transform: $btn-text-transform !default;
// $burger-btn-icon-breakpoint: null !default; // What viewport width do you want to remove the 'menu' text from the burger button?
// $burger-btn-bar-breakpoint: null !default; // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
// $nav-normal-max-width: $container-max-width !default; // $container-max-width
// $nav-normal-margin-top: 0 !default;
$nav-normal-margin-bottom: 0;
// $nav-normal-contents-max-width: $container-max-width !default;
$nav-normal-align-items: right;
// $nav-normal-border-radius: $border-radius !default;
$nav-normal-mobile-background-colour: lighten($brand-secondary, 10%);
$nav-normal-mobile-top-level-item-colour: $body-colour;
// $nav-normal-mobile-submenu-background-colour: rgba($black, 0.08) !default;
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour) !default;
// $nav-normal-mobile-chevrons-enabled: true !default;
// $nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour) !default;

// Nav - Parent nav items clickable
// $nav-parent-clickable-enabled: false !default;
// $nav-parent-clickable-chevron-background-colour: rgba(#fff, 0.75) !default;
// $nav-parent-clickable-chevron-colour: $nav-normal-mobile-background-colour !default;
// $nav-parent-clickable-chevron-icon-type: "chevron" !default; // can take "chevron" or "plus"
// $nav-parent-clickable-chevron-active-background-colour: #fff !default;
// $nav-parent-clickable-chevron-active-colour: $nav-parent-clickable-chevron-colour !default;


// Specifically when no banner present and you have gone for $header-absolute: true
// $no-banner-header-background-colour: $header-background-colour !default;
// $no-banner-logo: $logo !default;
// $no-banner-logo-retina: $no-banner-logo !default;
// $no-banner-tagline-colour: $tagline-colour !default;
// $no-banner-social-icons-header-background-colour: $social-icons-header-background-colour !default; // A colour, transparent, or 'brand'
// $no-banner-social-icons-header-colour: $social-icons-header-colour !default; // A colour, or 'brand'
// $no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour !default; // A colour, or 'brand'
// $no-banner-nav-background-colour: $nav-background-colour !default;
// $no-banner-nav-top-level-item-colour: $nav-top-level-item-colour !default;
// $no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
// $no-banner-header-search-input-placeholder-colour: $header-search-input-placeholder-colour !default;
// $no-banner-header-search-input-border-colour: $header-search-input-border-colour !default;
// $no-banner-header-search-input-background-colour: $header-search-input-background-colour !default;
// $no-banner-header-search-button-background-colour: $header-search-button-background-colour !default;
// $no-banner-header-search-button-icon-colour: $header-search-button-icon-colour !default;


// Carousel
$carousel-max-width: 100%;
// $carousel-border-radius: $border-radius !default;
// $carousel-margin-top: 0 !default;
// $carousel-margin-bottom: 0 !default;
// $carousel-image-overlay: null !default; // Add a colour value or gradient here
// $carousel-contents-max-width: $carousel-max-width !default; // How wide do you want .carouselDetailWrapper?
// $carousel-breakpoint: map-get($breakpoints, lg) !default;
// $carousel-details-style: overlay !default; // overlay, adjacent or below
$carousel-details-position-y: bottom;
$carousel-details-position-x: center;
$carousel-details-background-colour: $blue-accessible;
// $carousel-details-box-shadow: $box-shadow !default;
$carousel-details-max-width: 500px;
// $carousel-details-min-height: auto !default; // set to 100% if you want to use overlay, but have the details full height pre-breakpoint
$carousel-details-padding: 1.5rem;
$carousel-details-margin-y: -40px;
$carousel-details-margin-x: 40px;
// $carousel-details-border-radius: $border-radius !default;
$carousel-details-text-align: center;
// $carousel-heading-text-transform: $headings-text-transform !default;
$carousel-heading-colour: text-contrast($blue-accessible);
// $carousel-heading-font-size: $font-size-h2 !default;
// $carousel-summary-enabled: true !default;
// $carousel-summary-colour: text-contrast($carousel-details-background-colour) !default;
// $carousel-summary-font-size: inherit !default;
// $carousel-read-more-enabled: true !default;
// $carousel-read-more-background-colour: $btn-background-colour !default;
// $carousel-read-more-border-colour: $btn-border-colour !default;
// $carousel-read-more-colour: $btn-text-colour !default;
// $carousel-read-more-font-size: $carousel-summary-font-size !default;

// Carousel controls (left-right buttons)
// $carousel-controls-enabled: true !default;
// $carousel-controls-button-size: 40px !default;
// $carousel-controls-button-gap: 0px !default;
$carousel-controls-button-background-colour: $carousel-details-background-colour;
// $carousel-controls-button-border-radius: $border-radius !default;
// $carousel-controls-button-box-shadow: $box-shadow !default;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
// $carousel-controls-margin-y: 0px !default;
$carousel-controls-margin-x: 20px;
$carousel-controls-icon-colour: $brand-primary;
// $carousel-controls-icon-font-size: 1em !default;
// $carousel-controls-next-icon: "\f054" !default; // TODO: These are temporarily suspended
// $carousel-controls-prev-icon: "\f053" !default; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
// $carousel-buttons-enabled: true !default;
// $carousel-buttons-type: dots !default; // dots or tabs

// Carousel buttons dots
// $carousel-dots-position-y: bottom !default; // top, bottom
// $carousel-dots-position-x: center !default; // left, center, right
// $carousel-dots-padding: $spacer !default;
// $carousel-dots-colour: $white !default;
// $carousel-dots-opacity: 0.5 !default;
// $carousel-dots-active-colour: $brand-primary !default;

// Carousel buttons tabs
// $carousel-tabs-font-size: $font-size-h3 !default;
// $carousel-tabs-background-colour: $grey-lighter !default;
// $carousel-tabs-colour: $brand-primary !default;
// $carousel-tabs-active-background-colour: $brand-primary !default;
// $carousel-tabs-active-colour: text-contrast($carousel-tabs-active-background-colour) !default;
// $carousel-tabs-active-triangle-enabled: true !default;
// $carousel-tabs-text-align: left !default;
// $carousel-tabs-font-weight: $btn-font-weight !default;
// $carousel-tabs-text-transform: $btn-text-transform !default;
// $carousel-tabs-padding-x: $spacer !default;
// $carousel-tabs-padding-y: $spacer !default;
// $carousel-tabs-separator-width: 0px !default; // Width of border between tabs
// $carousel-tabs-separator-colour: $border-colour !default;

// Carousel video play button (before breakpoint, when carousel details present)
// $carousel-video-btn-position-y: 50% !default; // What percentage do you want the play button positioned vertically?
// $carousel-video-btn-position-x: 50% !default; // What percentage do you want the play button positioned laterally?

// Carousel scroll button
// $carousel-scroll-btn-enabled: false !default;
// $carousel-scroll-btn-size: $carousel-controls-button-size !default;
// $carousel-scroll-btn-background-colour: $btn-background-colour !default;
// $carousel-scroll-btn-border-radius: $border-radius !default;
// $carousel-scroll-btn-box-shadow: $box-shadow !default;
// $carousel-scroll-btn-icon-colour: $btn-text-colour !default;
// $carousel-scroll-btn-icon-font-size: 1em !default;
// $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
// $carousel-scroll-btn-margin-y: 15% !default; // Can use a negative value to get it to peek over the edge
// $carousel-scroll-btn-animation-prefab: 0 !default; // Pick a number from 0 (off) to 3!

// Carousel background YouTube video
// $carousel-yt-background-enabled: false !default;
// $carousel-yt-background-size: "cover" !default; // 'cover' or 'contain'
// $carousel-yt-cover-padding-bottom: 56.25% !default; // Play with this to insure you get proper coverage. This is a dark art, see https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed. I'm giving you control here to play around with it.

// Carousel after breakpoint
// $carousel-mobile-details-background-colour: $brand-primary !default;
// $carousel-mobile-heading-colour: text-contrast($carousel-mobile-details-background-colour) !default;
// $carousel-mobile-summary-colour: text-contrast($carousel-mobile-details-background-colour) !default;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
// $banner-min-height: 200px !default;


// Home intro
// $home-intro-max-width: 100% !default;
$home-intro-background-colour: transparent;
// $home-intro-background-gradient: none !default; // none or CSS gradient
// $home-intro-background-image: "stars.svg";
$home-intro-background-image-opacity: 0.25;
// $home-intro-box-shadow: $box-shadow !default;
$home-intro-content-max-width: 768px;
// $home-intro-content-align: center !default; // left, center or right
// $home-intro-padding-y: $spacer * 5 !default;
$home-intro-margin-top: 0;
// $home-intro-margin-bottom: 0 !default;
// $home-intro-text-align: center !default;
// $home-intro-text-transform: $headings-text-transform !default;
// $home-intro-font-family: $headings-font-family !default; // Will affect all elements within the home intro snippet
// $home-intro-font-size: $font-size-h2 !default;
// $home-intro-font-weight: $headings-font-weight !default;
// $home-intro-line-height: $line-height-base !default;
$home-intro-colour: $body-colour;
// $home-intro-border-radius: $border-radius !default;
// $home-intro-mobile-padding-y: $home-intro-padding-y * .7 !default;
// $home-intro-mobile-font-size: $home-intro-font-size * .85 !default;


// Home headlines intro
// $home-headlines-intro-enabled: false !default;
// $home-headlines-intro-snippet-title-enabled: false !default;
// $home-headlines-intro-max-width: 100% !default;
// $home-headlines-intro-content-max-width: $container-max-width !default;
// $home-headlines-intro-background-colour: $white !default;
// $home-headlines-intro-padding-top: $spacer * 5 !default;
// $home-headlines-intro-padding-bottom: 0 !default;
// $home-headlines-intro-margin-top: 0 !default;
// $home-headlines-intro-margin-bottom: 0 !default;
// $home-headlines-intro-text-align: center !default;
// $home-headlines-intro-text-transform: $headings-text-transform !default;
// $home-headlines-intro-font-family: $headings-font-family !default;
// $home-headlines-intro-font-size: $font-size-h2 !default;
// $home-headlines-intro-colour: text-contrast($home-headlines-intro-background-colour) !default;


// Home features
// $home-features-max-width: $container-max-width !default;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer * 5;
// $home-features-padding-bottom: $spacer * 5 !default;
// $home-features-cards-entry-animation-prefab: 0 !default; // See https://github.com/RaisingITDev/fresco/wiki/entry-animations
// $home-features-cards-entry-animation-stagger: true !default;
// $home-features-cards-entry-animation-stagger-count: 3 !default;


// Impact stats
// $impact-stats-enabled: true !default;
// $impact-stats-max-width: 100% !default;
// $impact-stats-margin-top: 0 !default;
// $impact-stats-margin-bottom: 0 !default;
// $impact-stats-padding-y: $spacer * 5 !default;
// $impact-stats-background-colour: $brand-primary !default;
// $impact-stats-background-gradient: none !default; // none or CSS gradient
// $impact-stats-background-image: null !default;
// $impact-stats-background-image-opacity: 0.5 !default;
// $impact-stats-background-image-size: cover !default;
// $impact-stats-background-image-repeat: no-repeat !default;
// $impact-stats-border-radius: $border-radius !default;
// $impact-stats-colour: text-contrast($impact-stats-background-colour) !default;
// $impact-stats-text-align: center !default; // left, center, right
// $impact-stats-content-max-width: $container-max-width !default;
// $impact-stats-breakpoint: map-get($breakpoints, md) !default; // When do you want to break the overall layout from row to column?

// $impact-stats-overall-container-direction: column !default; // column, row for the .homeImpactWrapper
// $impact-stats-direction: row !default; // Do you want the stats in a row or column?
// $impact-stats-mobile-text-align: $impact-stats-text-align !default;

// Heading
// $impact-stats-heading-enabled: true !default; //true or false
// $impact-stats-heading-font-size: $font-size-h2 !default;
// $impact-stats-heading-text-align: $impact-stats-text-align !default; // left, center, right
// $impact-stats-heading-colour: inherit !default;
// $impact-stats-heading-margin-bottom: $spacer * 3 !default;
// $impact-stats-heading-text-transform: $headings-text-transform !default;

// Individual stat
// $impact-stat-min-width: 180px !default; // Use this to control how many you get in a row
// $impact-stat-direction: column !default; // column, row
// $impact-stat-padding: 0px 0px !default;
// $impact-stat-margin: $gap-width !default;
// $impact-stat-border: 0px solid $border-colour !default; // Style border here
// $impact-stat-border-width: 0 0 0 0 !default; // Set border widths (t-r-b-l) here

// Figure
// $impact-stats-figure-colour: $impact-stats-colour !default;
// $impact-stats-figure-font-family: $headings-font-family !default;
// $impact-stats-figure-font-size: 3.5em !default;

// Summary
// $impact-stats-summary-colour: inherit  !default;
// $impact-stats-summary-font-size: $font-size-large !default;
// $impact-stats-summary-font-weight: $font-weight-base !default;
// $impact-stats-summary-margin-top: 0 !default;


// Home feeds
// $home-feeds-max-width: $container-max-width !default;
// $home-feeds-background-colour: $white !default;
// $home-feeds-padding-top: $spacer * 5 !default;
// $home-feeds-padding-bottom: $spacer * 5 !default;
// $home-feeds-cards-entry-animation-prefab: 0 !default; // See https://github.com/RaisingITDev/fresco/wiki/entry-animations
// $home-feeds-cards-entry-animation-stagger: true !default;
// $home-feeds-cards-entry-animation-stagger-count: 3 !default;

// Feeds title
// $feeds-title-font-family: $headings-font-family !default;
// $feeds-title-font-size: $font-size-h2 !default;
// $feeds-title-colour: text-contrast($home-feeds-background-colour) !default;
$feeds-title-text-align: center;
// $feeds-title-margin-bottom: $spacer !default;
// $feeds-title-text-transform: $headings-text-transform !default;

// Feed item
// $feed-item-read-more-enabled: false !default;

// Event feed items
// $feed-item-event-start-date-enabled: true !default;
// $feed-item-event-location-enabled: true !default;

// Twitter feed
// $home-feed-twitter-enabled: false !default;
// $home-feed-twitter-card-gap-width: $card-gap-width !default;
// $home-feed-twitter-card-border: $card-border !default;
// $home-feed-twitter-card-border-radius: $card-border-radius !default;
// $home-feed-twitter-card-box-shadow: $card-box-shadow !default;
// $home-feed-twitter-card-text-align: $card-text-align !default;
// $home-feed-twitter-card-padding: $card-details-padding !default;
// $home-feed-twitter-card-background-colour: $card-details-background-colour !default;
// $home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour) !default;
// $home-feed-twitter-card-min-width: $card-min-width !default;


// Footer
// $footer-boxes-count: 4 !default;
// $footer-prefab: 1 !default;
// $footer-max-width: 100% !default;
// $footer-contents-max-width: $container-max-width !default;
// $footer-margin-y: 0 !default;
// $footer-padding-y: $spacer * 2 !default;
// $footer-background-colour: $grey-lightest !default;
// $footer-background-gradient: none !default; // none or CSS gradient
// $footer-colour: text-contrast($footer-background-colour) !default;
// $footer-link-colour: $link-colour !default;
// $footer-link-decoration: underline !default;
// $footer-link-hover-colour: darken($footer-link-colour, 10%) !default;
// $footer-link-hover-decoration: underline !default;
// $footer-text-align: left !default;
// $footer-font-size: inherit !default;
// $footer-headings-font-size: inherit !default;
// $footer-headings-text-transform: $headings-text-transform !default;

// Footer admin links
// $footer-admin-basket-link-enabled: true !default;
// $footer-admin-login-link-enabled: true !default;
// $footer-admin-manager-link-enabled: true !default;
// $footer-admin-my-details-link-enabled: true !default;
// $footer-admin-logout-link-enabled: true !default;

// Newsletter
// $newsletter-background-colour: $brand-primary !default;
// $newsletter-background-gradient: none !default; // none or CSS gradient
// $newsletter-colour: text-contrast($newsletter-background-colour) !default;
// $newsletter-heading-font-family: $headings-font-family !default;
// $newsletter-heading-font-size: $font-size-h2 !default;
// $newsletter-heading-text-align: center !default; // left, center, right
// $newsletter-heading-text-transform: $headings-text-transform !default;
// $newsletter-heading-margin-bottom: $spacer * 2 !default;
// $newsletter-heading-colour: $newsletter-colour !default;
// $newsletter-input-background-colour: $white !default;
// $newsletter-button-height: 38px !default; // Hard code this so you can match it with the inputs
// $newsletter-button-background-colour: $brand-secondary !default;
// $newsletter-button-colour: text-contrast($newsletter-button-background-colour) !default;
// $newsletter-button-border-colour: $brand-secondary !default;
// $newsletter-use-placeholders: false !default; // Turn on to use placeholders in the inputs rather than labels
// $newsletter-required-label-enabled: false !default;

// Newsletter hero - full width form above rest of footer
// $newsletter-hero-padding-y: $spacer * 3 !default;
// $newsletter-hero-contents-max-width: $footer-contents-max-width !default;


// Context container
// $context-container-background-colour: $grey-lightest !default;
// $context-container-padding: $spacer * 2 !default;
// $context-container-border-radius: $border-radius !default;

// Donors list
// $donors-list-enabled: true !default;
// $donors-list-profile-pics-enabled: $profile-pics-enabled !default;


// Posts
// $post-content-max-width: $container-max-width !default;
// $post-body-background-colour: transparent !default;


// Header text (container for breadcrumb and page title)
// $header-text-margin-top: $spacer * 3 !default;
// $header-text-margin-bottom: $spacer * 1.5 !default;


// Page title
// $page-title-font-size: $font-size-h1 !default;
// $page-title-font-family: $headings-font-family !default;
// $page-title-font-weight: $headings-font-weight !default;
// $page-title-colour: $headings-colour !default;
// $page-title-text-transform: $headings-text-transform !default;
// $page-title-text-align: left !default; // left, center or right
// $page-title-margin-top: 0 !default;
// $page-title-margin-bottom: $headings-margin-bottom !default;
// $page-title-md-font-size: $page-title-font-size * 0.8 !default; // <= $breakpoints.md
// $page-title-sm-font-size: $page-title-font-size * 0.6 !default; // <= $breakpoints.sm

// Page title over banner
// $page-title-over-banner-enabled: false !default;
// $page-title-over-banner-breakpoint: map-get($breakpoints, md);
// $page-title-over-banner-background-colour: $brand-primary !default; // Add a background colour so that title is always legible as it appears over a photo
// $page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour) !default;
// $page-title-over-banner-breadcrumb-enabled: true !default; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird


// Sidebar
// $sidebar-enabled: true !default; // Sidebars will always exist on event and appeal posts
// $sidebar-layout: adjacent !default; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
// $sidebar-mobile-layout: below !default; // above or below - where does the sidebar go on mobile?
// $sidebar-adjacent-width: 300px !default;
// $sidebar-adjacent-gap: $spacer * 5 !default; // distance between sidebar and post content
// $sidebar-background-colour: transparent !default;
// $sidebar-padding: 0 !default;
// $sidebar-border-radius: $context-container-border-radius !default;

// Associated lists
// $associated-latest-enabled: true !default;
// $associated-related-enabled: true !default;
// $associated-most-read-enabled: true !default;
// $associated-tag-cloud-enabled: false !default;
// $associated-max-number-of-posts: 4 !default;
// $associated-list-heading-font-size: $font-size-h4 !default;
// $associated-item-image-enabled: false !default;
// $associated-item-heading-font-size: $font-size-base !default;
// $associated-item-heading-colour: $link-colour !default;
// $associated-item-headings-text-transform: $headings-text-transform !default;
// $associated-item-summary-enabled: false !default;
// $associated-item-border: true !default;


// Listing
// $listing-content-max-width: $container-max-width !default;
// $listing-intro-max-width: $listing-content-max-width !default;
// $listing-body-background-colour: transparent !default;
// $listing-cards-per-row-desktop: 3 !default;
// $listing-cards-per-row-tablet: 2 !default;
// $listing-card-min-width: $card-min-width !default; // Either a px value or 100%. Adjust this value to dictate how many listing items you want in a single row

// Listed post settings
// $listed-post-read-more-enabled: false !default;
// $listed-news-post-publish-date-enabled: true !default;
// $listed-blog-post-publish-date-enabled: true !default;
// $listed-blog-post-author-enabled: true !default;
// $listed-event-post-start-date-enabled: true !default;
// $listed-event-post-location-enabled: true !default;
// $listed-appeal-totaliser-enabled: true !default;


// Breadcrumb
// $breadcrumb-enabled: true !default;
// $breadcrumb-disabled-on-listing: false !default; // Opt out of showing breadcrumbs on listing pages, if breadcrumbs are enabled
// $breadcrumb-margin-top: $spacer !default;
// $breadcrumb-margin-bottom: $spacer !default;
// $breadcrumb-max-width: $post-content-max-width !default;
// $breadcrumb-text-align: $page-title-text-align !default; // left, center or right


// Blockquote
// $blockquote-max-width: $post-content-max-width !default; // Either a px value or 100%
$blockquote-background-colour: $brand-secondary;
// $blockquote-background-gradient: none !default; // none or CSS gradient
// $blockquote-border-radius: $border-radius !default;
// $blockquote-margin-y: $spacer * 2 !default;
// $blockquote-padding: $spacer * 2 !default;
// $blockquote-text-align: center !default; // left, center, right
// $blockquote-font-size: 1.5em !default; // Em unit will cascade to children
// $blockquote-font-family: $headings-font-family !default;
// $blockquote-font-weight: $headings-font-weight !default;
// $blockquote-line-height: $line-height-base !default;
$blockquote-colour: text-contrast($blockquote-background-colour) !default;
// $blockquote-box-shadow: $box-shadow !default;


// Fundraising
// $fundraising-enabled: true !default; // Quickly turn off fundraising features

// Find a fundraiser widget
// $find-a-fundraiser-widget-enabled: true !default;
// $find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled !default;

// Top fundraisers
// $top-fundraisers-enabled: true !default;
// $top-fundraisers-profile-pics-enabled: $profile-pics-enabled !default;


// Donation form
// $donation-form-layout: columns !default; // rows or columns
// $donation-form-banner-enabled: true !default;
$donation-form-frequency-tabs: true;

// Donation amounts
// $donation-amount-min-width: $card-min-width !default; // IF using columns layout, set a min-width value on the amounts to dictate how many columns you want. Default will be 3 (items grow to fill row)
// $donation-amount-figure-font-size: $font-size-h2 !default;
// $donation-amount-figure-colour: $donate-colour !default;
// $donation-amount-thumbnail-enabled: true !default;


// Quick giving panel
// $quick-giving-type: simple !default; // simple, columns or rows
// $quick-giving-background-colour: $context-container-background-colour !default;
// $quick-giving-background-gradient: none !default; // none or CSS gradient
// $quick-giving-padding: $context-container-padding !default;
// $quick-giving-border-radius: $context-container-border-radius !default;
// $quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75 !default;
// $quick-giving-donation-amount-padding: 0.5rem 1rem !default;
// $quick-giving-donation-amount-background-colour: $donate-colour !default;
// $quick-giving-donation-amount-figure-colour: text-contrast($quick-giving-donation-amount-background-colour) !default;
// $quick-giving-donation-amount-border-width: $btn-border-width !default;
// $quick-giving-donation-amount-border-colour: $donate-colour !default;
// $quick-giving-donation-amount-selected-background-colour: $white !default;
// $quick-giving-donation-amount-selected-figure-colour: text-contrast($quick-giving-donation-amount-selected-background-colour) !default;


// Home quick giving panel
// $home-quick-giving-enabled: true !default;
// $home-quick-giving-type: adjacent;
// $home-quick-giving-max-width: 100% !default;
// $home-quick-giving-content-max-width: 100% !default;
// $home-quick-giving-margin-top: 0 !default;
// $home-quick-giving-margin-bottom: 0 !default;
// $home-quick-giving-padding-y: $spacer * 5 !default;
// $home-quick-giving-background-colour: $quick-giving-background-colour !default;
// $home-quick-giving-background-gradient: $quick-giving-background-gradient !default; // none or CSS gradient
// $home-quick-giving-background-image: null !default;
// $home-quick-giving-background-image-opacity: 0.5 !default;
$home-quick-giving-donation-amount-background-colour: #fff;
// $home-quick-giving-donation-amount-figure-colour: text-contrast($home-quick-giving-donation-amount-background-colour) !default;
// $home-quick-giving-donation-amount-border-width: $btn-border-width !default; // Only comes into play with transparent background
// $home-quick-giving-donation-amount-border-colour: $donate-colour !default;
$home-quick-giving-donation-amount-selected-background-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-figure-colour: #fff;
// $home-quick-giving-donate-btn-background-colour: $btn-background-colour !default;
// $home-quick-giving-donate-btn-colour: $btn-text-colour !default;
// $home-quick-giving-donate-btn-border-colour: $btn-border-colour !default;
// $home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size !default;
// $home-quick-giving-colour: $body-colour !default; // This should cover any headings and text you have in the snippet
// $home-quick-giving-heading-colour: $headings-colour !default;
// $home-quick-giving-heading-font-size: $font-size-h2 !default;

// Adjacent homepage quick giving
// $home-quick-giving-type-adjacent-image-side: left !default; // If type is adjacent, which side do you want the image

// Overlay homepage quick giving
// $home-quick-giving-type-overlay-items-side: left !default; // If type is overlay, which side do you want the copy and donation items?
// $home-quick-giving-type-overlay-gradient: linear-gradient(to left, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.75) 100%) !default;


// Button actions
// $button-actions-padding: 15px !default;
// $button-actions-background-colour: $context-container-background-colour !default;
// $button-actions-align-buttons: left !default; // left, center, right


// Totalisers
// $totaliser-enabled: true !default;
// $totaliser-layout: horizontal !default; // vertical or horizontal
// $totaliser-width: 60px !default;
// $totaliser-panel-background-colour: transparent !default;


// Comments
// $comment-add-image-enabled: true !default;
// $comment-add-video-enabled: true !default;


// Blog details
// $blog-details-enabled: true !default;
// $blog-details-background-colour: $context-container-background-colour !default;
// $blog-details-padding: $context-container-padding !default;
// $blog-details-border-radius: $context-container-border-radius !default;
// $blog-details-profile-pic-enabled: $profile-pics-enabled !default;


// FAQs
// $faq-content-max-width: $post-content-max-width !default;
// $faq-item-header-background-colour: $context-container-background-colour !default;
// $faq-item-header-padding: $context-container-padding !default;
// $faq-item-header-border-radius: $context-container-border-radius !default;
// $faq-item-header-font-size: $font-size-h4 !default;
// $faq-item-btn-background-colour: $btn-background-colour !default;
// $faq-item-btn-chevron-colour: $btn-text-colour !default;
// $faq-item-hover-btn-background-colour: button-hover-state($faq-item-btn-background-colour) !default;
// $faq-item-hover-btn-chevron-colour: text-contrast($faq-item-hover-btn-background-colour) !default;


// In Memory
// $listing-inmem-masonry-layout: true !default;


// Shop
// $shop-enabled: true !default; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)
// $basket-link-only-on-shop-pages: false !default; // Set to true to only show the basket link (in both header and footer admin menus) on 'shop' related pages only

// Departments list
// $department-list-width: $sidebar-adjacent-width !default;
// $department-list-background-colour: $context-container-background-colour !default;
// $department-list-padding: $context-container-padding !default;
// $department-list-border-radius: $context-container-border-radius !default;

// Product-price
// $product-price-colour: $brand-secondary !default;
// $product-price-font-size: $font-size-h4 !default;

// Product post
// $product-post-content-max-width: $post-content-max-width !default;

// Product rating
// $product-rating-enabled: true !default;


// Subsite
// $subsite-enabled: false !default;
// $subsite-hide-main-header: false !default;
// $subsite-hide-main-tagline: false !default;
// $subsite-hide-header-search: false !default;
// $subsite-hide-main-cta: false !default;
// $subsite-content-max-width: $container-max-width !default;
// $subsite-title-font-size: $page-title-font-size !default;

// Subsite logo
// $subsite-logo: "logo.svg" !default; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
// $subsite-logo-retina: $subsite-logo !default;
// $subsite-logo-width: 120px !default;
// $subsite-logo-height: 120px !default;
// $subsite-logo-margin: $header-content-margin-top 0 0 !default;

// Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint !default;
// $subsite-nav-max-width: $nav-normal-max-width !default;
// $subsite-nav-margin-top: $spacer !default;
// $subsite-nav-margin-bottom: $spacer !default;
// $subsite-nav-background-colour: $nav-background-colour !default;
// $subsite-nav-contents-max-width: $container-max-width !default;
// $subsite-nav-align-items: $nav-normal-align-items !default; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding !default;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour !default;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour !default;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;


// Cookie consent
// $cookie-consent-position: bottom !default; // Bottom or top
// $cookie-consent-background-colour: $black !default; // You can use rgba here for opacity
// $cookie-consent-colour: text-contrast($cookie-consent-background-colour) !default;
// $cookie-consent-font-family: initial !default;
// $cookie-consent-btn-background-colour: $btn-background-colour !default;
// $cookie-consent-btn-text-colour: $btn-text-colour !default;


// Keeping In Touch
// $keeping-in-touch-enabled: true !default;
// $keeping-in-touch-via-email-enabled: true !default;
// $keeping-in-touch-via-telephone-enabled: true !default;
// $keeping-in-touch-via-sms-enabled: true !default;
// $keeping-in-touch-via-post-enabled: true !default;


// Slick
// $slick-enabled: false !default;


// @import "helpers/namespace";